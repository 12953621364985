/* Media query */

@media (max-width: 1399.98px) {
    .about_page_section .About_page_img_area .about_page_img_video {
        width: 250px;
        height: 150px;
    }

    .Team_section_hp .Team_item_area {
        margin: 0 0px;
    }

    .Property_Detail_Section .Property_Detail_images_list_area .Property_Detail_images_item .Property_Detail_images_content p {
        -webkit-line-clamp: 6;
    }
}

@media (max-width: 1199.98px) {

    .contact_section .contact_info_item_Area a,
    .contact_section .contact_info_item_Area p {
        font-size: 14px;
    }

    .Property_Detail_Section .Property_Detail_images_list_area .Property_Detail_images_item .Property_Detail_images_content p {
        -webkit-line-clamp: 8;
    }

    .Searching_property_list_item .Searching_property_owner_Price_area .Searching_property_owner_item h6 {
        font-size: 14px;
    }

    .Searching_property_list_item .Searching_property_owner_Price_area .Searching_property_owner_item img {
        width: 30px;
        height: 30px;
    }

    .Searching_property_list_item .Searching_property_list_img {
        height: 200px;
    }

    .Searching_property_list_item .Searching_property_list_content h5 {
        font-size: 16px;
    }


    .Team_section_hp .Team_item_area {
        margin: 0 0px;
    }

}

@media (max-width: 1199.98px) {

    .contact_section .contact_info_item_Area a,
    .contact_section .contact_info_item_Area p {
        font-size: 14px;
    }

    .featured_properties_content .carousel {
        margin-bottom: 20px;
    }
}

@media (max-width: 991.98px) {
    .carousel-caption_from .Find_your_Top .item_list {
        flex-basis: 45%;
        margin-bottom: 10px;
    }

    .about_us_sl .about_us_content_sl p {
        line-height: 23px;
    }

    .Property_Detail_contact_section .Property_Detail_contact_top_img img {
        height: 400px;
    }

    .Property_Detail_Section .Property_Detail_images_list_area .Property_Detail_images_item img {
        height: 350px;
    }

    .Searching_property_list_item .Searching_property_owner_Price_area .Searching_property_owner_item h6 {
        font-size: 16px;
    }

    .Searching_property_list_item .Searching_property_owner_Price_area .Searching_property_owner_item img {
        width: 35px;
        height: 35px;
    }

    .about_page_section .About_page_img_area .about_page_img_video {
        width: 400px;
        height: 250px;
    }

    .about_page_section .About_page_img_area .about_page_img_video {
        width: 400px;
        height: 250px;
    }

    .footer>.row>div {
        margin-bottom: 10px;
    }

    .Services_section_hp .Service_short_detail_area {
        -webkit-line-clamp: 5;
    }
    .Searching_filter_topbar_section .Searching_filter_topbar_bgWhite .Searching_filter_search_input_hp{
        min-width: 318px;
    }
    .more-dropdown button + .dropdown-content{
        width: 324px;   
    }
}

@media (max-width: 767.98px) {
    .Property_Detail_Section .Property_Detail_content_area_hp .Property_Detail_heading_content_area_hp {
        display: flex;
        align-items: start;
        justify-content: space-between;
        flex-direction: column;
    }

    .Searching_filter_topbar_section .Searching_filter_topbar_right_Area {
        margin-top: 10px;
    }

    .Property_Detail_Section .Property_Detail_images_list_area .Property_Detail_images_item .Property_Detail_images_content p {
        -webkit-line-clamp: 9;
    }

    .footer>.row>div {
        margin-bottom: 10px;
    }

    .footer>.row>div {
        margin-bottom: 10px;
    }

    .about_page_section .about_page_white_bg_hp .About_page_img_area .about_page_img_video {
        display: none;
    }

    .about_page_section .about_page_white_bg_hp .About_page_img_area .about_page_img_item {
        text-align: center;
    }

    .about_page_section .about_page_white_bg_hp .About_page_img_area .about_page_img_item img {
        width: 80%;
        margin: auto;
    }

    .Services_section_hp .Service_short_detail_area {
        -webkit-line-clamp: 8;
    }

    .about_page_section .About_page_img_area .about_page_img_item {
        text-align: center;
    }

    .about_page_section .About_page_img_area .about_page_img_item img {
        width: 90%;
    }

    .about_page_section .About_page_img_area .about_page_img_video {
        display: none;
    }

    .carousel-caption_from .Find_your_Top .item_list {
        flex-basis: 100%;
    }

    .carousel-caption_from .Find_your_Top .item_list_MV {
        width: 100%;
    }

    .featured_properties_sl .text-end {
        text-align: left !important;
    }

    .featured_properties_sl .featured_properties_content .carousel-caption_about .crousel-caption_about_right .crousel-caption_about_right_bottom .detail button {
        border-radius: 10px;
        padding: 3px 11px;
        font-size: 14px;
    }

    .properties-left-caption_about .row {
        display: block;
    }
    .Searching_filter_topbar_section .Searching_filter_topbar_bgWhite .Searching_filter_search_input_hp {
        min-width: 315px;
    }
}

@media (max-width: 575.98px) {
    .GeographicalFilters_area_hp .GeographicalFilters_content_area_hp {
        width: 90%;
        height: auto;
        padding: 10px;
        border-radius: 10px;
        background: #fff;
        margin-top: 10px;
    }

    .Property_Detail_Section .Property_Detail_images_list_area .Property_Detail_images_item .Property_Detail_images_content p {
        -webkit-line-clamp: 12;
    }

    .Searching_property_list_item .Searching_property_list_content {
        padding: 15px 10px;
    }

    .Searching_property_list_item .Searching_property_owner_Price_area {
        padding: 15px 10px;
    }

    .Searching_property_list_item .Searching_property_owner_Price_area .Searching_property_owner_item img {
        width: 30px;
        height: 30px;
    }

    .Searching_property_list_item .Searching_property_owner_Price_area .Searching_property_owner_item h6 {
        font-size: 14px;
    }

    .Searching_filter_topbar_section .Searching_filter_topbar_left_Area p {
        font-size: 16px;
    }

    /* .Searching_filter_topbar_section .Searching_filter_topbar_right_Area {
       display: none;
    } */

    .Searching_filter_topbar_section .Searching_filter_topbar_right_Area .form-group.d-flex {
        flex-direction: column;
        width: 100%;
        align-items: start !important;
        text-align: start !important;
    }

    .Searching_filter_topbar_section .Searching_filter_topbar_right_Area .Searching_filer_icon_area {
        display: flex;
        align-items: center;
        margin-top: 10px;
        width: 100%;
        align-items: start;
    }

    .Container_white_bg_hp {
        padding: 20px 10px;
    }

    .Container_white_bg_hp {
        padding: 20px 10px;
    }

    .Services_section_hp .Service_item_area {
        padding: 20px 10px;
    }

    .Services_section_hp .Service_short_detail_area {
        -webkit-line-clamp: 10;
    }

    .footer>.row>div {
        margin-bottom: 10px;
    }

    .footer>.row>div>h5 {
        font-size: 15px;
    }

    .footer>.row>div>div>ul>li>a {
        font-size: 12px;
    }

    .footer>.row>div>.row>.col-12>p {
        font-size: 12px;
    }

    .footer>.row>div>.row>.col-12>p>a {
        font-size: 14px;
    }

    .Property_Detail_contact_section .Property_Detail_contact_top_img img {
        height: 160px;
    }

    .Property_Detail_contact_section h4 {
        font-size: 15px;
    }

    .Property_Detail_contact_section p {
        line-height: 1.3;
    }

    .Property_Detail_Section .Property_Detail_images_list_area .Property_Detail_images_item .Property_Detail_images_content {
        padding: 10px;
        bottom: -50px;
        width: 95%;
        left: 2.5%;
    }

    .Property_Detail_Section .Property_Detail_images_list_area .Property_Detail_images_item .Property_Detail_images_content h4 {
        font-size: 14px;
        font-weight: 600;
    }

    .Property_Detail_Section .Property_Detail_images_list_area .Property_Detail_images_item .Property_Detail_images_content p {
        font-size: 10px;
        font-weight: 400;
    }

    .footer>.row>div>.row>.col-12>p>a {
        font-size: 14px;
    }

    .carousel-caption_from .Find_your_Top .item_list {
        flex-basis: 100%;
    }

    .carousel-caption_from .Find_your_Top .item_list_MV {
        width: 100%;
    }

    .properties-left-caption_about .carousel-caption_about_left p {
        font-size: 12px;
        margin-bottom: 0px;
    }

    .Ask_Inquiries_sl .Ask_Inquiries_sl_content_sl p {
        font-size: 15px;
        line-height: 25px;
        color: #fff;
    }
    .btn_00529B {
        padding: 5px 10px;
        font-size: 12px;
    }

    .Searching_filter_topbar_section .Searching_filter_topbar_bgWhite .Searching_filter_search_input_hp {
        min-width: 251px;
    }
    .Searching_filter_topbar_section .Searching_filter_topbar_right_Area .Searching_loation_icon_Area{
        height: 30px;
        width: 30px;
    }
    .more-dropdown button + .dropdown-content {
        width: 264px;
    }
    .more-dropdown button {
        padding: 8px 21px;
        font-size: 13px;
    }
    .GeographicalFilters_area_hp .GeographicalFilters_content_area_hp select {
        padding: 7px 5px;
        font-size: 12px;
    }
    .GeographicalFilters_area_hp .GeographicalFilters_content_area_hp .PropertyTypeFilters_icon_hp{
        width: 100%;
        height: 73px;
    }
}